  /*
  ** VUEX Store for loading MXM sources trough FE
  */

import { deserialize } from 'jsonapi-deserializer';

export const strict = false;

export const state = () => ({
  stream: [],
  killStream: false,
  streamStatus: [],
  socketToken: false,
  socketStatus: false,
  preroll: false,
  playerState: {
    status: 'stop',
    type: false,
    fullscreen: false
  },
  videoOptions: {
    autoplay: false,
    poster: false,
  },
  updateStreamIntervalId: null,
})

export const actions = {   
  getStream({commit, rootState}, query) {
    var url = process.env.API_URL + process.env.API_PREFIX + '/stream/get/' + query.resource_id + '/' + query.video_id + '?query=(include:(source:()))';
    if (query.phone_stream_id) {
      url = process.env.API_URL + process.env.API_PREFIX + '/stream/get/' + query.resource_id + '/' + query.video_id + '/' + query.phone_stream_id + '?query=(include:(source:()))';
    }
    this.$axios.get(url)
    .then(response => {
      response.data.data.type = query.type;
      response.data.data.location = query.location;
      if(query.type == 'preroll') {
        response.data.data.preroll_info = {};
        response.data.data.preroll_info.href = query.href;
        response.data.data.preroll_info.target = query.target;
      }
      commit('setStream', response.data);
    }).catch((error) => {
      console.log(error);
      if (query.phone_stream_id) {
        commit('setKillStream', true);
      }
      this.$sentry.captureException(error, {
        tags: { 
          getStream: true,
          user_id: rootState.auth.loggedIn ? rootState.auth.user.user.id : false
        } 
      });
    });  
  }, 
  updateStream(context, data) {
    this.$axios.get(process.env.API_URL + process.env.API_PREFIX + '/phone-stream-active/' + data.stream_id)
    .then(response => {
      context.commit('setStreamState', response);
      context.commit('setKillStream', false);
    }).catch((error) => {
      context.commit('setKillStream', true);
      this.$sentry.captureException(error);
    });
  },
  startUpdateStream({ commit, dispatch }, dataObj) {
    commit('clearUpdateStreamInterval');

    const intervalId = setInterval(() => {
      dispatch('updateStream', dataObj);
    }, 10000);
    commit('setUpdateStreamIntervalId', intervalId);
  },
  stopUpdateStream({ commit }) {
    commit('clearUpdateStreamInterval');
  }
}
export const getters = {
  stream(state) {
    return state.stream;
  },
  killStream(state) {
    return state.killStream;
  },
  socketToken(state) {
    return state.socketToken;
  },
  streamStatus(state) {
    return state.streamStatus;
  },
  socketStatus(state) {
    return state.socketStatus;
  },
  playerOptions(state) {
    return state.playerOptions;
  },
  playerState(state, status) {
    return state.playerState;
  },
}

export const mutations = {
  setStream(state, data) {
    state.stream.serps = '';
    state.stream = data.data;
  },
  setKillStream(state, action) {
    state.killStream = action;    
  },
  setStreamState(state, data) {
    state.streamState = data.data;    
  },
  setSocketStatus(state, data) {
    state.socketState = data;    
  },
  setSocketToken(state, data) {
    state.socketToken = data.data.token;    
  },
  setStreamStatus(state, data) {
    state.streamState = data.data;    
  },
  updatePlayerOptions(state, data) {
    console.log('updatePlayerOptions');
    console.log(data);
    state.playerOptions = data;        
  },
  setPlayerStatus(state, status) {
    state.playerState.status = status;
  },
  setPlayerType(state, type) {
    console.log('setPlayerType');
    console.log(type);
    state.playerState.type = type;
  },
  setPlayerFullscreen(state, todo) {
    state.playerState.fullscreen = todo;
  },
  setPrerollStatus(state, todo) {
    state.preroll = todo;
  },
  clearStream(state) {
    state.stream = [];
    state.killStream = false;
    state.streamStatus = [];
    state.socketToken = false;
    state.socketStatus = false;
    state.playerState.status = 'stop';
    state.playerState.type = false;
    state.videoOptions.autoplay = false;
    state.videoOptions.poster = false;
    if (state.updateStreamIntervalId) {
      clearInterval(state.updateStreamIntervalId);
      state.updateStreamIntervalId = null;
    }
  },
  clearStreamForPreroll(state) {
    state.stream = [];
    state.killStream = false;
    state.streamStatus = [];
    state.socketToken = false;
    state.socketStatus = false;
    // state.playerState.status = 'stop';
    // state.playerState.type = false;
    // state.videoOptions.autoplay = false;
    // state.videoOptions.poster = false;
  },
  setUpdateStreamIntervalId(state, intervalId) {
    state.updateStreamIntervalId = intervalId;
  },
  clearUpdateStreamInterval(state) {
    if (state.updateStreamIntervalId) {
      clearInterval(state.updateStreamIntervalId);
      state.updateStreamIntervalId = null;
    }
  }
}
