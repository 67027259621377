/*
** JS functions to we use on multiple components
*/
import { mapState } from 'vuex' 
import Vue from 'vue'
// Vue.directive('inFocusHorizontal', {
//   inserted: (el, binding, vnode) => {
//     let f = () => {
//       let rect = el.getBoundingClientRect()
//       let inView = (
//         rect.width > 0 &&
//         rect.height > 0 &&
//         rect.top >= 0 &&
//         rect.left <= (window.innerWidth || document.documentElement.clientWidth)
//         )
//       if (inView && !isScrolled) {
//         isScrolled = true;
//         if (binding.value[2] === 'promo') {
//           impression.GaPromoImpression(binding.value);
//         }
//         if (binding.value[2] === 'banner') {
//           impression.GaBannerImpression(binding.value);
//         }
//         if (binding.value[2] === 'cam') {
//           impression.GaCamImpression(binding.value);
//         }
//         if (binding.value[2] === 'sexfilm') {
//           impression.GaImpression(binding.value);
//         }

//       }
//     }

//     let container = el.parentElement;
//     container.addEventListener('scroll', f);
//     var isScrolled = false;
//     setTimeout(function() {
//       f()
//     }, 500);
//   }
// })
// Vue.directive('inFocusVertical', {
//   inserted: (el, binding, vnode) => {
//     let f = () => {
//       if (el.classList.contains('showElement')) {
//         return;
//       }
//       let rect = el.getBoundingClientRect()
//       let inView = (
//         rect.width > 0 &&
//         rect.height > 0 &&
//         rect.top >= 0 &&
//         rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
//         )
//       if (inView && !isScrolled) {
//         isScrolled = true;
//         if (binding.value[2] === 'promo') {
//           impression.GaPromoImpression(binding.value);
//         }
//         if (binding.value[2] === 'banner') {
//           impression.GaBannerImpression(binding.value);
//         }
//         if (binding.value[2] === 'cam') {
//           impression.GaCamImpression(binding.value);
//         }
//         if (binding.value[2] === 'sexfilm') {
//           impression.GaImpression(binding.value);
//         }
//       }
//     }
//     window.addEventListener('scroll', f);
//     var isScrolled = false;
//     setTimeout(function() {
//       f()
//     }, 500);
//   }
// })
export const impression = {
  GaImpression: function(item){
    if (typeof gtag === 'function') {
      var resource = item[0];
      var position = item[1] + 1;
      var brand = item[2];
      var list = item[3];
      gtag('event', 'view_item_list', {
        'id': resource.id,
        'name': resource.slug,
        'list_name': list,
        'brand': brand,
        'list_position': position,
        "quantity": 1
      })
    }
  },
  GaPromoImpression: function(item) {
    if (typeof gtag === 'function') {
      var resource = item[0];
      var list = item[3];
      gtag('event', 'view_promotion', {
        'id': resource.id,
        'name': resource.slug + '-' + list,
      })
    }
  },
  GaCamImpression: function(item) {
    if (typeof gtag === 'function') {
      var resource = item[0];
      var list = item[3];
      gtag('event', 'view_promotion', {
        'id': resource.id,
        'name': resource.modelnaam + '-Location=' + list,
      })
    }
  },
  GaBannerImpression: function(item) {
    if (typeof gtag === 'function') {
      var resource = item[0];
      var list = item[3];
      gtag('event', 'view_promotion', {
        'id': resource.id,
        'name': resource.href + '-Location=' + list,
      })
    }
    if (typeof ga === 'function') {
      ga('ec:addPromo', {
        'id': 'banner_' + resource.id,
        'name': resource.href,
        'creative': list + '_banner_promotion',
        'position': 1
      })
    }
  }
};

Vue.mixin({
  computed: {
    ...mapState({
      analytics: ({ analyticsStore: {analytics} }) => analytics
    })
  },
  methods: {
    GaSelectPromoContent(resource, index, list, brand){
      if (typeof gtag === 'function') {
        gtag('event', 'select_content', {
          "promotions": [
            {
              "id": resource.id,
              "name": resource.slug + '-' + list
            }
          ]
        });
      }
    },
    GaSelectBannerContent(resource, index, list, brand){
      if (typeof gtag === 'function') {
        gtag('event', 'select_content', {
          "promotions": [
            {
              "id": resource.id,
              "name": resource.href + '-' + list
            }
          ]
        });
      }
      if (typeof ga === 'function') {
        ga('ec:setAction', 'promo_click');
        ga('send', 'event', list + '_banner_promotion', 'click', 'banner_' + resource.id); 
      }
    },
    GaSelectCamContent(resource, index, list, brand){
      if (typeof gtag === 'function') {
        gtag('event', 'select_content', {
          "promotions": [
            {
              "id": resource.id,
              "name": resource.modelnaam + '-' + list
            }
          ]
        });
      }
    },
    GaSelectContent(resource, index, list, brand){
      var analyticsObj = {
        resource: resource,
        brand: brand,
        category: false,
        price: false,
        list_position: index + 1,
        list_name: list
      };
      if (typeof gtag === 'function') {
        gtag('event', 'select_content', {
          "content_type": "product",
          "items": [{
            'id': analyticsObj.resource.id,
            'name': analyticsObj.resource.slug,
            'brand': analyticsObj.brand,
            'list_name': analyticsObj.list_name,
            'list_position': analyticsObj.list_position,
            'category': analyticsObj.category,
            'price': analyticsObj.price
          }]
        });
      }
      this.$store.commit('analyticsStore/updateFullAnalyticsObject', analyticsObj);
    },
    GaViewItem(resource){
      if (this.analytics.resource.id == resource.id) {
        if (typeof gtag === 'function') {
          gtag('event', 'view_item', {
            "content_type": "product",
            "items": [{
              'id': this.analytics.resource.id,
              'name': this.analytics.resource.slug,
              'brand': this.analytics.brand,
              'list_name': this.analytics.list_name,
              'list_position': this.analytics.list_position,
              'category': false,
              'price': false
            }]
          });
        }
      } else {
        var analyticsObj = {
          resource: resource,
          brand: 'sexfilm',
          list_name: 'rechtstreeks',
          list_position: 1,
          category: false,
          price: false
        };
        if (typeof gtag === 'function') {
          gtag('event', 'view_item', {
            "content_type": "product",
            "items": [{
              'id': analyticsObj.resource.id,
              'name': analyticsObj.resource.slug,
              'brand': analyticsObj.brand,
              'list_name': analyticsObj.list_name,
              'list_position': analyticsObj.list_position,
              'category': false,
              'price': false
            }]
          });
        }
        this.$store.commit('analyticsStore/updateFullAnalyticsObject', analyticsObj);
      }
    },
    GaAddToCart(resource, variant){
      if (this.analytics.resource.id == resource.id) {
        if (typeof gtag === 'function') {
          gtag('event', 'add_to_cart', {
            "content_type": "product",
            "items": [{
              'id': this.analytics.resource.id,
              'name': this.analytics.resource.slug,
              'brand': this.analytics.brand,
              'list_name': this.analytics.list_name,
              'list_position': this.analytics.list_position,
              'category': false,
              'price': false,
              'variant': location
            }]
          });
        }
      } else {
        var analyticsObj = {
          resource: resource,
          brand: 'sexfilm',
          list_name: 'rechtstreeks',
          list_position: 1,
          category: false,
          price: false
        };
        if (typeof gtag === 'function') {
          gtag('event', 'add_to_cart', {
            "content_type": "product",
            "items": [{
              'id': analyticsObj.resource.id,
              'name': analyticsObj.resource.slug,
              'brand': analyticsObj.brand,
              'list_name': analyticsObj.list_name,
              'list_position': analyticsObj.list_position,
              'category': false,
              'price': false,
              'variant': variant
            }]
          });
        }
        this.$store.commit('analyticsStore/updateFullAnalyticsObject', analyticsObj);
      }
    },
    GaRemoveFromCart(resource){
      if (this.analytics.resource.id == resource.id) {
        if (typeof gtag === 'function') {
          gtag('event', 'remove_from_cart', {
            "content_type": "product",
            "items": [{
              'id': this.analytics.resource.id,
              'name': this.analytics.resource.slug,
              'brand': this.analytics.brand,
              'list_name': this.analytics.list_name,
              'list_position': this.analytics.list_position,
              'category': false,
              'price': false,
              'variant': this.analytics.variant
            }]
          });
        }
      }
    },
    GaBeginCheckout(resource, variant){
      if (this.analytics.resource.id == resource.id) {
        if (typeof gtag === 'function') {
          gtag('event', 'begin_checkout', {
            "content_type": "product",
            "items": [{
              'id': this.analytics.resource.id,
              'name': this.analytics.resource.slug,
              'brand': this.analytics.brand,
              'list_name': this.analytics.list_name,
              'list_position': this.analytics.list_position,
              'variant': variant,
              'category': false,
              'price': false
            }]
          });
        }
      } else {
        var analyticsObj = {
          resource: resource,
          brand: 'sexfilm',
          variant: 'paywall_sexfilm',
          list_name: 'rechtstreeks_paywall',
          list_position: 1,
          'category': false,
          'price': false
        };
        if (typeof gtag === 'function') {
          gtag('event', 'begin_checkout', {
            "content_type": "product",
            "items": [{
              'id': analyticsObj.resource.id,
              'name': analyticsObj.resource.slug,
              'brand': analyticsObj.brand,
              'list_name': analyticsObj.list_name,
              'list_position': analyticsObj.list_position,
              'variant': analyticsObj.list_position,
              'category': false,
              'price': false
            }]
          });
        }
        this.$store.commit('analyticsStore/updateFullAnalyticsObject', analyticsObj);
      }
    },
    GaCheckoutProgress(resource, paywallObject){
      if (this.analytics.resource.id == resource.id) {
        if (typeof gtag === 'function') {
          gtag('event', 'checkout_progress', {
            "content_type": "product",
            "items": [{
              'id': this.analytics.resource.id,
              'name': this.analytics.resource.slug,
              'brand': this.analytics.brand,
              'list_name': this.analytics.list_name,
              'list_position': this.analytics.list_position,
              'category': paywallObject.category,
              'price': paywallObject.price
            }]
          });
        }
        this.$store.commit('analyticsStore/updateCatPrice', paywallObject);
      } else {
        var analyticsObj = {
          resource: resource,
          brand: 'sexfilm',
          list_name: 'rechtstreeks_paywall',
          list_position: 1,
          category: paywallObject.category,
          price: paywallObject.price
        };
        if (typeof gtag === 'function') {
          gtag('event', 'checkout_progress', {
            "content_type": "product",
            "items": [{
              'id': analyticsObj.resource.id,
              'name': analyticsObj.resource.slug,
              'brand': analyticsObj.brand,
              'list_name': analyticsObj.list_name,
              'list_position': analyticsObj.list_position,
              'category': paywallObject.category,
              'price': paywallObject.price
            }]
          });
        }
        this.$store.commit('analyticsStore/updateFullAnalyticsObject', analyticsObj);
      }
      let cnamePr = 'GaPrice';
      let cvaluePr = this.analytics.price;
      let exdaysPr = 1;
      this.storeCookie(cnamePr, cvaluePr, exdaysPr);
      let cnameCa = 'GaCategory';
      let cvalueCa = this.analytics.category;
      let exdaysCa = 1;
      this.storeCookie(cnameCa, cvalueCa, exdaysCa);
      let cnameLN = 'GaListName';
      let cvalueLN = this.analytics.list_name;
      let exdaysLN = 1;
      this.storeCookie(cnameLN, cvalueLN, exdaysLN);
      let cnameLP = 'GaListPosition';
      let cvalueLP = this.analytics.list_position;
      let exdaysLP = 1;
      this.storeCookie(cnameLP, cvalueLP, exdaysLP);
    },
    GaSetCheckoutOption(paywallObject){
      if (typeof gtag === 'function') {
        gtag('event', 'set_checkout_option', {
          "checkout_step": paywallObject.checkout_step,
          "checkout_option": paywallObject.checkout_option,
          "value": paywallObject.value
        });
      }
    },
    GaPurchase(paywallObject){
      if (typeof gtag === 'function') {
        gtag('event', 'purchase', {
          "transaction_id": paywallObject.transaction_id,
          "affiliation": "Meidenvanholland.nl",
          "value": paywallObject.price,
          "currency": "EUR",
          "items": [
            {
              "id": paywallObject.resource.id,
              "name": paywallObject.resource.slug,
              "brand": paywallObject.brand,
              "category": paywallObject.category,
              "list_name": paywallObject.list_name,
              "list_position": paywallObject.list_position,
              "price": paywallObject.price
            },
          ]
        });
      }
    },
    GaEvent(eventObject){
      if (typeof gtag === 'function') {
        gtag('event', eventObject.action, {
          "event_category": eventObject.event_category,
          "event_label": eventObject.event_label,
          "value": eventObject.value
        });
      }
    },
  }
})