export default {
  computed: {
    subscriptionExists() {
      return () => Array.isArray(this.$auth.user?.user?.subscriptions?.data);
    },
    subscriptionHistoryExists() {
      return () => Array.isArray(this.$auth.user?.user?.subscriptions_history?.data);
    },
    favoritesExists() {
      return (type) => Array.isArray(this.$auth.user?.user?.favorites?.data?.[type]);
    },
    hasRecurringSubscription() {
      return () => {
        if (!this.subscriptionExists()) return false;
        return this.$auth.user.user.subscriptions.data.some(subscription => subscription.recurring !== null);
      }
    },
    recurringSubscription() {
      return () => {
        if (!this.subscriptionExists()) return false;
        const subscription = this.$auth.user.user.subscriptions.data.find(subscription => subscription.recurring !== null);
        return subscription ? subscription : false;
      }
    },
    hasDirectSubscription() {
      return () => {
        if (!this.subscriptionExists()) return false;
        return this.$auth.user.user.subscriptions.data.some(subscription => 
          subscription.subscription &&
          subscription.subscription.slug &&
          subscription.subscription.slug === 'direct'
          );
      }
    },
    directSubscription() {
      if (!this.subscriptionExists()) return false;
      const subscription = this.$auth.user.user.subscriptions.data.find(subscription => 
        subscription.active === 1 &&
        subscription.subscription &&
        subscription.subscription.slug &&
        subscription.subscription.slug === 'direct'
        );
      return subscription ? subscription : false;
    },
    hasActiveSubscription() {
      return (type) => {
        if (!this.subscriptionExists()) return false;
        return this.$auth.user.user.subscriptions.data.some(subscription => 
          subscription.resource &&
          subscription.resource.type === type
          );
      }
    },
    hasHistorySubscription() {
      return (type) => {
        if (!this.subscriptionHistoryExists()) return false;
        return this.$auth.user.user.subscriptions_history.data.some(subscription => 
          subscription.resource &&
          subscription.resource.type === type
          );
      }
    },
    hasFavoriteResource() {
      return (type) => {
        if (!this.favoritesExists(type)) return false;
        return this.$auth.user.user.favorites.data[type] &&
        Array.isArray(this.$auth.user.user.favorites.data[type]) &&
        this.$auth.user.user.favorites.data[type].some(favorite => favorite);
      }
    },
    isFavoriteResource() {
      return (type, id) => {
        if (!this.favoritesExists(type)) return false;
        return this.$auth.user.user.favorites.data[type] &&
        Array.isArray(this.$auth.user.user.favorites.data[type]) &&
        this.$auth.user.user.favorites.data[type].some(favorite => 
         favorite && favorite.id === id
         );
      }
    },
  },
  methods: {
    // Returns a function that filters and maps user subscriptions based on type.
    userRentedResources(type) {
      if (!this.subscriptionExists()) return false;
      return this.$auth.user.user.subscriptions.data
      .filter(subscription => subscription.resource && subscription.resource.type === type && subscription.active === 1)
      .map(subscription => subscription.resource);
    },
    userRentedHistoryResources(type) {
      if (!this.subscriptionHistoryExists()) return false;
      return this.$auth.user.user.subscriptions_history.data
      .filter(subscription => subscription.resource && subscription.resource.type === type && subscription.active === 1)
      .map(subscription => subscription.resource);
    },
    // Returns a function that filters and maps user favorites based on type.
    userFavoriteResources(type) {
      if (!this.favoritesExists(type)) return false;
      const favoritesOfType = this.$auth.user.user.favorites.data[type];
      return favoritesOfType
      // .map(favorite => favorite.resource);
    }
  }
}
