// Use to check if a modal popup is active

export const strict = false;

export const state = () => ({
  status: {
    step: 1,
    view: 'closed',
    paymethod: 'ideal',
  },
  paymentQuery: {
    subscription_detail_id: 1,
    payment_method_id: 10,
    return_url: '',
  },
  account: null,
  error: null, 
  loading: false,
});

export const actions = {
  async purchase({ commit, state, rootState }, { subscription_detail_id, slug, path }) {
    commit('setLoading', 'loading');
    commit('updatePaymentQuery', { key: 'return_url', value: process.env.BASE_URL + '/transactie' });
    if (rootState.resourcesStore.video && path.includes('sexfilms')) {
      commit('updatePaymentQuery', {
        key: 'return_url',
        value: process.env.BASE_URL + '/transactie?type=sexfilms&slug=' + rootState.resourcesStore.video.slug,
      });
      commit('updatePaymentQuery', { key: 'resource_id', value: rootState.resourcesStore.video.id });
    }
    else if (rootState.resourcesStore.episode && path.includes('series')) {
      commit('updatePaymentQuery', {
        key: 'return_url',
        value: process.env.BASE_URL + '/transactie?type=series&slug=' + rootState.resourcesStore.episode.show[0].slug + '/' + rootState.resourcesStore.episode.slug,
      });
      commit('updatePaymentQuery', { key: 'resource_id', value: rootState.resourcesStore.episode.id });
    }     
    else if (rootState.resourcesStore.private && path.includes('privefilmpjes')) {
      commit('updatePaymentQuery', {
        key: 'return_url',
        value: process.env.BASE_URL + '/transactie?type=privefilmpjes&slug=' + rootState.resourcesStore.private.slug,
      });
      commit('updatePaymentQuery', { key: 'resource_id', value: rootState.resourcesStore.private.id });
    }
    else if (path.includes('account')) {
      commit('updatePaymentQuery', {
        key: 'return_url',
        value: process.env.BASE_URL + '/transactie?slug=' + path,
      });
    }
    commit('updatePaymentQuery', { key: 'subscription_detail_id', value: subscription_detail_id });
    if (slug === 'credit') {
      commit('updatePaymentQuery', { key: 'subscription_detail_id', value: null });
      commit('updatePaymentQuery', { key: 'credit_bundle_id', value: subscription_detail_id });
    }
    await this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/payment/' + slug, state.paymentQuery)
    .then(response => { 
      commit('setPurchase', response.data);
    }).catch((error) => {
      console.log(error);
      commit('setLoading', 'error');
      this.$sentry.captureException(error, {
        tags: { 
          purchase: true,
          user_id: rootState.auth.loggedIn ? rootState.auth.user.user.id : false
        } 
      });
    }); 
  },
  checkCoupon({commit, rootState, state}, {coupon, slug, subscription_detail_id}){
    // Route::post('/coupon/check-subscription', [CouponController::class, 'checkSubscription']);
    // Route::post('/coupon/check-credit', [CouponController::class, 'checkCredit']);
    var dataObj = {
      code: coupon
    };

    if (slug === 'credit') {
      dataObj.credit_bundle_id = subscription_detail_id;
    } else {
      dataObj.subscription_detail_id = subscription_detail_id;
    }
    this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/coupon/check-' + slug, dataObj)
    .then(response => { 
      console.log(response.data.result);
      if (response.data.result != 'success') {
        var alertObj = {
          type: 'info',
          message: 'Deze kortingscode is helaas niet geldig.',
        };
        commit('alertStore/setAlert', alertObj, { root: true });
        return;
      }
      commit('subscriptionsStore/setCouponDiscount', response.data, { root: true });
      var couponData = {
        key: 'coupon',
        value: coupon
      }
      return commit('updatePaymentQuery', couponData); 
    }).catch((error) => {
      console.log(error);
      this.$sentry.captureException(error);
        // commit('setLoading', 'error');
    }); 
  },
  resetCoupon({commit, rootState, state}){
    commit('subscriptionsStore/setResetCouponSub', true, { root: true });
    var couponData = {
      key: 'coupon',
      value: null
    }
    return commit('updatePaymentQuery', couponData); 
  }
}

export const mutations = {
  setView(state, value) {
    state.status.view = value;
  },
  setStep(state, value) {
    if (value === 'back') {
      state.status.step = state.status.step > 0 ? state.status.step - 1 : 0;
    } else if (value === 'forward') {
      state.status.step = state.status.step <= 2 ? state.status.step + 1 : 4;
    } else {
      state.status.step = value;
    }

    if(state.status.step === 4) {
      state.status.view = 'maximized'
    } else {
      state.status.view = 'active'
    }
  },
  setPaymethod(state, paymethod) {
    state.status.paymethod = paymethod.name;
    state.paymentQuery.payment_method_id = paymethod.id;
    return
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setAccountState(state, value) {
    state.account = value;
  },
  updatePaymentQuery(state, query) {
    if (query.value === null) {
      delete state.paymentQuery[query.key];
      return
    }
    state.paymentQuery[query.key] = query.value;
  },
  setPurchase(state, response) {
    state.loading = 'done';
    try {
      new URL(response.data.redirect_url); 
      window.location.href = response.data.redirect_url;
    } catch (e) {
      console.error("Invalid redirect URL:", e);
      state.loading = "error";
    }
  }

}

const jsonToUrlValue = (value) => {
  if (typeof value === 'number' || typeof value === 'boolean' || value === null) {
    return String(value);
  } else if (typeof value === 'string') {
        // Quote the string if necessary
    if (['true', 'false', 'null'].includes(value) || !isNaN(Number(value))) {
      return `'${value}'`;
    }
    return value.replace(/ /g, '+');
  } else if (Array.isArray(value)) {
    return `(${value.map(jsonToUrlValue).join(',')})`;
  } else if (typeof value === 'object') {
    const entries = Object.entries(value).map(([key, val]) => `${key}:${jsonToUrlValue(val)}`);
    return `(${entries.join(',')})`;
  }
    // Fallback for undefined or functions
  return '';
};

const objectToJsonUrl = (obj) => {
  return encodeURIComponent(jsonToUrlValue(obj));
};