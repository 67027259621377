import { deserialize } from 'jsonapi-deserializer';
import { formatDate } from '@/helpers/filters.js';
import { parse } from 'date-fns';

export const strict = false;

export const state = () => ({
  query: {
    page: 1,
    input: '',
  },
  result: [],
});

export const actions = {
  getSearchResult(context, query) {
    var loadingObj = {
      content: 'search',
      type: 'new',
    };
    if (query.page > 1) {
      loadingObj.type = 'map';
    }
    context.commit('loadstateStore/setLoadState', loadingObj, { root: true });
    this.$axios
      .get(
        process.env.API_URL +
          '/search?page=' +
          query.page +
          '&q=' +
          query.input +
          '&frontend_id=5'
      )
      .then((response) => {
        var paginationObj = {
          content: 'search',
          pagination: response.data.meta.pagination,
        };
        context.commit('loadstateStore/setPagination', paginationObj, {
          root: true,
        });
        context.commit('setResult', response.data);
        loadingObj.type = 'done';
        context.commit('loadstateStore/setLoadState', loadingObj, {
          root: true,
        });
      })
      .catch((error) => {
        console.log(error);
        loadingObj.type = 'error';
        context.commit('loadstateStore/setLoadState', loadingObj, {
          root: true,
        });
        this.$sentry.captureException(error);
      });
  },
};

export const getters = {
  result(state) {
    return state.result;
  },
};

export const mutations = {
  setResult(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var result = deserialize(dataObj);

    result.forEach(function (video) {
      video.images.forEach(function (element) {
        if (element.types == 'thumb') {
          video.thumb = element.sizes;
        }
        if (element.types == 'cover') {
          video.cover = element.sizes;
        }
        if (element.types == 'home_cover') {
          video.home_cover = element.sizes;
        }
      });
      if (video.products) {
        video.products.forEach(function (element) {
          // date comes in DD/MM/YY format and parsed here to Date. Native js expect MM/DD/YY
          video.date = formatDate(
            parse(element.pivot_data.active_from, 'd/M/yy', new Date())
          );
        });
      }
      if (video.product) {
        // date comes in DD/MM/YY format and parsed here to Date. Native js expect MM/DD/YY
        video.date = formatDate(
          parse(video.product.active_from, 'd/M/yy', new Date())
        );
      }
      if (video.videos.free_film) {
        video.video_free = video.videos.free_film[0];
        video.video_free.duration = video.video_free.duration.substring(3, 5);
      }
      if (video.videos.film) {
        video.video_paid = video.videos.film[0];
        video.video_paid.duration = video.video_paid.duration.substring(3, 5);
      }
      if (video.videos.trailer) {
        video.trailer = video.videos.trailer[0];
      }
    });

    if (data.meta.pagination.current_page == 1) {
      state.result = result;
    } else {
      state.result = state.result.concat(result);
    }
  },

  setQuery(state, set) {
    state.query[set.type] = set.name;
  },
};
