const middleware = {}

middleware['add-param'] = require('../middleware/add-param.js')
middleware['add-param'] = middleware['add-param'].default || middleware['add-param']

middleware['auth-detection'] = require('../middleware/auth-detection.js')
middleware['auth-detection'] = middleware['auth-detection'].default || middleware['auth-detection']

middleware['device-detection'] = require('../middleware/device-detection.js')
middleware['device-detection'] = middleware['device-detection'].default || middleware['device-detection']

middleware['redirect-if-logged-in'] = require('../middleware/redirect-if-logged-in.js')
middleware['redirect-if-logged-in'] = middleware['redirect-if-logged-in'].default || middleware['redirect-if-logged-in']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['user'] = require('../middleware/user.js')
middleware['user'] = middleware['user'].default || middleware['user']

export default middleware
